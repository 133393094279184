import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const AddingUsers = () => {
  return (
    <LayoutComponent>
      <h2>Adding Collaborators</h2>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Team members can only access GAINSS if they are a member of an
          organization. They must first be invited by an organization
          administrator. Once invited, team members will receive an email with
          instructions to join the organization on the platform. After accepting
          the invitation and completing the account setup, they will have full
          access to the organization's resources and projects on GAINSS.
        </p>
      </div>
      <h6>Inviting Users</h6>
      <p>
        After registering and setting up your organization, you as the
        oraganization admin can invite other users to join your organization.
      </p>
      <ol>
        <li>
          Navigate to the Users page. Here you can view and manage users in your
          organization.
        </li>
        <li>
          Click on <strong>Invite New Member</strong> and enter their email
          address amd name.
        </li>
        <li>
          They will receive an invitation email to join your organization.
        </li>
        <li>
          Upon accepting the invite, they can complete their account setup and
          gain access to the platform.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default AddingUsers
